.chatbot-avatar {
  pointer-events: none;
  border-radius: 4px !important;
  & > img {
    border-radius: 4px !important;
  }
}

.conversation-header {
  background-color: var(--primary_green) !important;
  color: var(--neutral_white);
  justify-content: center;
  .content {
    .cs-conversation-header__user-name {
      font-weight: 600;
      line-height: 24px;
    }
    & > div {
      background-color: var(--primary_green);
      color: var(--neutral_white);
      font-family: 'Montserrat-Regular';
    }
  }
  .cs-conversation-header__avatar {
    width: 22px;
    margin-right: 9px;
    .icon {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        width: 22px;
      }
    }
  }
}

.message {
  &.cs-message--incoming {
    .cs-message__content-wrapper {
      .cs-message__content {
        background-color: var(--neutral_grey);
      }
    }
  }
  &.cs-message--outgoing {
    .cs-message__content-wrapper {
      .cs-message__content {
        background-color: var(--primary_green);
        color: var(--neutral_white);
      }
    }
  }
}

.empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
  text-align: center;
  max-width: 215px;
  margin: auto;
  color: var(--primary_green_dark);
  .avatar {
    height: 53.73px !important;
    width: 66px !important;
  }
}

.message-input {
  border-top: unset !important;
  .cs-message-input__content-editor-wrapper {
    background-color: inherit;
    border: 1px solid var(--neutral_grey_light);
  }
  .cs-message-input__content-editor {
    background-color: var(--background);
  }
  & button {
    color: var(--primary_green);
    &:disabled {
      color: var(--neutral_grey_dark);
    }
  }
}

.typing-indicator {
  margin-left: 12px;
  .cs-typing-indicator__dot {
    background-color: var(--primary_green_light);
    height: 8px;
    width: 8px;
    &:not(:last-child) {
      margin-right: 7px;
    }
  }
}
