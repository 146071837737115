.App {
  --primary_green: rgb(13, 158, 122);
  --primary_green_dark: rgb(0, 84, 54);
  --primary_green_light: rgb(132, 208, 186);
  --primary_green_white: rgb(224, 243, 239);

  --secondary_yellow: rgb(255, 175, 59);
  --secondary_yellow_light: rgb(255, 221, 172);

  --palo_green: rgb(5, 204, 152);
  --palo_green_dark: rgb(56, 230, 128);
  --palo_green_light: rgb(255, 175, 59);
  --palo_green_white: rgb(239, 128, 53);

  --neutral_white: rgb(255, 255, 255);
  --neutral_grey: rgb(247, 247, 247);
  --neutral_grey_light: rgb(205, 208, 207);
  --neutral_grey_dark: rgb(106, 113, 111);
  --neutral_black: rgb(32, 40, 38);

  --background: rgb(255, 255, 255);

  --error: rgba(211, 47, 47, 1);

  --text_primary: rgb(34, 34, 34);
  --text_secondary: rgb(114, 114, 114);

  --stroke: rgba(0, 0, 0, 0.12);

  // add palo brand colors
  --brand_blue: rgb(66, 106, 234);
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Montserrat-Semibold';
  src: url('./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Open-Sans-Regular';
  src: url('./assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf')
    format('truetype');
}

.headline1-desktop {
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.25px;
}

.headline2-desktop {
  font-family: 'Montserrat-Bold';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.headline3-desktop {
  font-family: 'Montserrat-Semibold';
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.1px;
}

.button1-desktop {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.side-menu-button {
  text-align: start;
}

.subtitle1-desktop {
  font-family: 'Montserrat-Medium';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.subtitle2-desktop {
  font-family: 'Montserrat-Medium';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.body1-desktop {
  font-family: 'Open-Sans-Regular';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.body2-desktop {
  font-family: 'Open-Sans-Regular';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.caption-desktop {
  font-family: 'Open-Sans-Regular';
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.MuiPaper-root {
  background-color: white;
}
